
<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_number'])"></div>
        <DefaultInput
            :label="$t('poshta_number.localization_value.value')"
            :type="'text'"
            v-model="id"
        />
      </div>

      <div class="table-filter__item"
           v-if="$store.getters.getIsAdminRights === 'admin'"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_user',])"></div>
        <SearchSelect
            :options="options"
            :label="$t('common_user.localization_value.value')"
            :optionsLabel="'email.contacts[0]'"
            :functionSearch="functionSearchUserFilter"
            @change="changeUsersFilter"
            :selected="userName"
            :typeSelect="'users'"
            :userEmail="true"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_Created',])"></div>
        <DatePickerDefault
            :label="$t('poshta_Created.localization_value.value')"
            v-model="date"
        >
          <template slot="body">
            <date-picker
                v-model="date"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_trackingNumber',])"></div>
        <DefaultInput
            :label="$t('poshta_trackingNumber.localization_value.value')"
            :type="'text'"
            v-model="trackingNumber"
        />
      </div>

<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['poshta_Shipping',])"></div>-->
<!--        <DefaultInput-->
<!--            :label="$t('poshta_Shipping.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            v-model="shippingCost"-->
<!--        />-->
<!--      </div>-->

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_Shipped',])"></div>
        <DatePickerDefault
            :label="$t('poshta_Shipped.localization_value.value')"
            v-model="shipped"
        >
          <template slot="body">
            <date-picker
                v-model="shipped"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_PayPalTransaction'])"></div>
        <DefaultInput
            :label="$t('poshta_PayPalTransaction.localization_value.value')"
            :type="'text'"
            v-model="payPalTransaction"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_recipientContactName'])"></div>
        <DefaultInput
            :label="$t('poshta_recipientContactName.localization_value.value')"
            :type="'text'"
            v-model="recipientName"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_recipientPhone'])"></div>
        <DefaultInput
            :label="$t('poshta_recipientPhone.localization_value.value')"
            :type="'text'"
            v-model="recipientPhone"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_recipientEmail'])"></div>
        <DefaultInput
            :label="$t('poshta_recipientEmail.localization_value.value')"
            :type="'text'"
            v-model="recipientEmail"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_recipientAddress'])"></div>
        <DefaultInput
            :label="$t('poshta_recipientAddress.localization_value.value')"
            :type="'text'"
            v-model="recipientAddress"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_recipientCity'])"></div>
        <DefaultInput
            :label="$t('poshta_recipientCity.localization_value.value')"
            :type="'text'"
            v-model="recipientCity"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_recipientRegion'])"></div>
        <DefaultInput
            :label="$t('poshta_recipientRegion.localization_value.value')"
            :type="'text'"
            v-model="recipientRegion"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_recipientCountry'])"></div>
        <DefaultSelect
            :label="$t('poshta_recipientCountry.localization_value.value')"
            :options="optionsCountries"
            :optionsLabel="'label'"
            :selected="getCountrySelected()"
            @change="changeCountry"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_recipientZipCode'])"></div>
        <DefaultInput
            :label="$t('poshta_recipientZipCode.localization_value.value')"
            :type="'text'"
            v-model="recipientZip"
        />
      </div>

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {filterCountriesUSA} from "../../../../../../../services/filterCountries";

  export default {
    name: "BelposhtaFilter",
    components: {
      SearchSelect,
      FilterBlock,
      MainButton,
      DatePickerDefault,
      DatePicker,
      DefaultInput,
      DefaultSelect,
    },

    props: {
      showFilter: Boolean,
      filterGetParams: Object,
    },

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',
        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY'): '',
        payPalTransaction: this.filterGetParams.payPalTransaction ? this.filterGetParams.payPalTransaction : '',
        trackingNumber: this.filterGetParams.trackingNumber ? this.filterGetParams.trackingNumber : '',
        // shippingCost: this.filterGetParams.shippingCost ? this.filterGetParams.shippingCost : '',
        shipped: this.filterGetParams.shipped ? this.generateFilterDate(this.filterGetParams.shipped, 'MM/DD/YY'): '',
        recipientName: this.filterGetParams.recipientName ? this.filterGetParams.recipientName : '',
        recipientPhone: this.filterGetParams.recipientPhone ? this.filterGetParams.recipientPhone : '',
        recipientEmail: this.filterGetParams.recipientEmail ? this.filterGetParams.recipientEmail : '',
        recipientAddress: this.filterGetParams.recipientAddress ? this.filterGetParams.recipientAddress : '',
        recipientCity: this.filterGetParams.recipientCity ? this.filterGetParams.recipientCity : '',
        recipientRegion: this.filterGetParams.recipientRegion ? this.filterGetParams.recipientRegion : '',
        recipientCountryId: this.filterGetParams.recipientCountryId ? this.filterGetParams.recipientCountryId : '',
        recipientZip: this.filterGetParams.recipientZip ? this.filterGetParams.recipientZip : '',

        options: [],
        optionsCountries: [],

        countrySelected: {},

        filterCounts: [
          'id',
          'userId',
          'userName',
          'date',
          'payPalTransaction',
          'trackingNumber',
          // 'shippingCost',
          'shipped',
          'recipientName',
          'recipientPhone',
          'recipientEmail',
          'recipientAddress',
          'recipientCity',
          'recipientRegion',
          'recipientCountryId',
          'recipientZip',
        ],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.id = newVal.id ? newVal.id : ''
        this.userId = newVal.userId ? newVal.userId : ''
        this.userName = newVal.userName ? newVal.userName : ''
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.payPalTransaction = newVal.payPalTransaction ? newVal.payPalTransaction : ''
        this.trackingNumber = newVal.trackingNumber ? newVal.trackingNumber : ''
        // this.shippingCost = newVal.shippingCost ? newVal.shippingCost : ''
        this.shipped = newVal.shipped ? this.generateFilterDate(newVal.shipped, 'MM/DD/YY') : ''
        this.recipientName = newVal.recipientName ? newVal.recipientName : ''
        this.recipientPhone = newVal.recipientPhone ? newVal.recipientPhone : ''
        this.recipientEmail = newVal.recipientEmail ? newVal.recipientEmail : ''
        this.recipientAddress = newVal.recipientAddress ? newVal.recipientAddress : ''
        this.recipientCity = newVal.recipientCity ? newVal.recipientCity : ''
        this.recipientRegion = newVal.recipientRegion ? newVal.recipientRegion : ''
        this.recipientCountryId = newVal.recipientCountryId ? newVal.recipientCountryId : ''
        this.recipientZip = newVal.recipientZip ? newVal.recipientZip : ''
      },

    },

    created() {

    },

    mounted() {
      this.$store.dispatch('fetchCountries').then(() => {
        let countries = this._.toArray(this.$store.getters.getCountries)
        filterCountriesUSA(countries)
        this.optionsCountries = countries;
        this.getCountrySelected()
      }).catch(error => this.createErrorLog(error))
    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.date = this.changeDateParams(this, 'date')
        data.shipped = this.changeDateParams(this, 'shipped')

        this.$emit('changeFilter', data)
      },

      getCountrySelected(){
        if(this.recipientCountryId === '') return ''
        return this._.find(this.optionsCountries, {id: parseInt(this.recipientCountryId)})
      },

      changeCountry(val) {
        this.recipientCountryId = val.id
      },
    },
  }
</script>

<style scoped>

</style>
