<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_createBelposhta'])"></div>
      <CardLeftBlock
          :backgroundImage="'orders'"
      >
        <template slot="body">
          <h2 class="mt-0">
            {{ $t('poshta_orderBelposhta.localization_value.value') }}
          </h2>
          <div class="card-detail-left__date">
            {{ new Date() | moment("DD MMM, YYYY") }}
          </div>
<!--          <div class="d-flex mt-3">-->
<!--            <div class="card-detail-left__name">-->
<!--              {{ $t('poshta_TrackNumber.localization_value.value') }}-->
<!--            </div>-->
<!--            <div class="card-detail-left__date ml-2">#180378436436</div>-->
<!--          </div>-->
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_backTo',])"></div>
                <router-link :to="$store.getters.GET_PATHS.belposhta" class="order-create__head-back">
                  <LinkBack
                      :value="$t('poshta_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['poshta_editBelposhta'])"></div>
              {{ $t('poshta_createBelposhta.localization_value.value') }}
            </div>
            <div class="order-create__head-txt"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['poshta_PleaseInformation'])"></div>
              {{ $t('poshta_PleaseInformation.localization_value.value') }}
            </div>
            <div
                v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                'poshta_sendingDetails',
                'poshta_shippingDetails',
                'poshta_recipient',
                'poshta_confirmation',
              ])"></div>

              <div class="order-create__head-steps"
                   v-if="$store.getters.getIsAdminRights === 'user'"
              >
                <ProgressSteps
                    :options="progressStepsSetting"
                    :step="activeStep"
                    @changeStep="stepChange"
                    @nextPageHandler="nextPageHandler"
                />
              </div>
            </div>
          </div>
        </template>
        <template slot="body">

          <div class="order-create__section">
            <div class="custom-row" v-if="$store.getters.getIsAdminRights === 'admin'">
              <div class="custom-col custom-col--33 custom-col--md-100">
                <UserSelect
                    :validationUser="belposhta.validation.user"
                    :validationTxtUser="belposhta.validationTxt.user"
                    :validationTranslateUser="belposhta.validationTranslate.user"
                    :userSelected="belposhta.data.user"
                    @changeUser="changeUser"
                    :typeSelect="'users'"
                    :userEmail="true"
                />
              </div>
            </div>
          </div>

          <StepOne v-if="activeStep === 1 || $store.getters.getIsAdminRights === 'admin'"
                   :belposhta="belposhta"
                   :personalInfoFrom="belposhta.data.PersonalInfoFrom"
                   :sendersAddressFrom="belposhta.data.SendersAddressFrom"
          />

          <StepTwo v-if="activeStep === 2 || $store.getters.getIsAdminRights === 'admin'"
                   :belposhta="belposhta"
                   :belposhtaProduct="belposhta.data.BelposhtaProduct"
                   :belposhtaPackaging="belposhta.data.packaging"
          />
          <StepThree v-if="activeStep === 3 || $store.getters.getIsAdminRights === 'admin'"
                     :belposhta="belposhta"
                     :personalInfoTo="belposhta.data.PersonalInfoTo"
                     :sendersAddressTo="belposhta.data.SendersAddressTo"/>

          <StepFour v-if="activeStep === 4"
                    :belposhta="belposhta"
                    @changeStep="step => {activeStep = step}"
          />

          <template  v-if="isAdmin">
            <div class="order-create__product-btn add-product-btn-wrap mb-2 mt-3"></div>

            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_shippingCost', ])"></div>
                <DefaultInput
                    :label="$t('poshta_shippingCost.localization_value.value')"
                    :type="'text'"
                    v-bind:class="{'ui-no-valid': belposhta.validation.shippingCost}"
                    :errorTxt="$t(`${belposhta.validationTranslate.shippingCost}.localization_value.value`)"
                    :error="belposhta.validation.shippingCost"
                    v-model="belposhta.data.shippingCost"
                />
              </div>
              <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_trackingNumber', ])"></div>
                <DefaultInput
                    :label="$t('poshta_trackingNumber.localization_value.value')"
                    :type="'text'"
                    v-bind:class="{'ui-no-valid': belposhta.validation.trackingNumber}"
                    :errorTxt="$t(`${belposhta.validationTranslate.trackingNumber}.localization_value.value`)"
                    :error="belposhta.validation.trackingNumber"
                    v-model="belposhta.data.trackingNumber"
                />
              </div>
            </div>
          </template>


        </template>
        <template slot="footer">
          <div class="order-create__footer">

            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_cancel', 'common_Previous'])"></div>
              <router-link :to="$store.getters.GET_PATHS.belposhta"
                           class="site-link site-link--alt"
                           v-if="activeStep == 1"
              >
                {{ $t('common_cancel.localization_value.value') }}
              </router-link>
              <span class="site-link site-link--alt"
                    @click="prevPageHandler()"
                    v-if="activeStep != 1"
              >
                {{ $t('common_Previous.localization_value.value') }}
              </span>
            </div>


            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_SaveInDrafts', 'common_drafts', 'common_savingInDrafts', 'poshta_create'])"></div>
              <MainButton
                  class="order-create__footer-btn-i secondary"
                  v-if="activeStep == 4 || isAdmin"
                  :value="$t('common_SaveInDrafts.localization_value.value')"
                  :tooltip="true"
                  @click.native="$emit('create', true)"
                  v-bind:class="{'disabled-btn' : $store.getters.getBelPoshtaBtn}"
              >
                <template slot="tooltip">
                  <p>
                    <b>{{ $t('common_drafts.localization_value.value') }}</b>
                  </p>
                  <p>
                    {{ $t('common_savingInDrafts.localization_value.value') }}
                  </p>
                </template>
              </MainButton>

              <MainButton
                  class="order-create__footer-btn-i"
                  :value="$t('common_Next.localization_value.value')"
                  @click.native="nextPageHandler()"
                  v-if="activeStep !== progressStepsSetting.length && $store.getters.getIsAdminRights === 'user'"
              />

              <MainButton
                  v-else
                  class="order-create__footer-btn-i white-space-nowrap__from-680"
                  :value="$t('common_create.localization_value.value')"
                  @click.native="$emit('create', false)"
                  v-bind:class="{'disabled-btn' : $store.getters.getBelPoshtaBtn}"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>


  </div>
</template>

<script>
import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
import ProgressSteps from "../../../../../UI/progress/ProgressSteps/ProgressSteps.vue";
import StepOne from "../../components/StepOne/StepOne";
import StepTwo from "../../components/StepTwo/StepTwo";
import StepThree from "../../components/StepThree/StepThree";
import StepFour from "../../components/StepFour/StepFour";
import {tabsMixins} from "../../../../../../mixins/creationPageMixins/tabsMixins";
import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
import {belposhtaOrdersMixins} from "../../../../../../mixins/internalOrdersMixins/belposhtaOrdersMixin";
import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "BelposhtaCreationUser",

  components: {
    DefaultInput,
    CardLeftBlock,
    MainButton,
    CardRightBlock,
    LinkBack,
    ProgressSteps,
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    UserSelect,
  },

  mixins: [
    tabsMixins,
    belposhtaOrdersMixins
  ],

  props: {
    belposhta: Object,
  },


  data() {
    return {
      payOrderPopup: false,
      progressStepsSetting: [
        this.$t('poshta_sendingDetails.localization_value.value'),
        this.$t('poshta_shippingDetails.localization_value.value'),
        this.$t('poshta_recipient.localization_value.value'),
        this.$t('poshta_confirmation.localization_value.value'),
      ],
      activeStep: 1,

      showingUpc: false,

      options: [{}],
    }
  },



  methods: {
    changePayOrderPopup(val) {
      this.payOrderPopup = val
    },

    nextPageHandler() {


      let valid = true

      switch (this.activeStep) {
        case 1:
          valid = this.belposhta.firstValidation()
          break
        case 2:
          valid = this.belposhta.secondValidation()
          break
        case 3:
          valid = this.belposhta.thirdValidation()
          break
        case 4:
          valid = this.belposhta.fourthValidation()
          break
      }

      if (valid)
        if (this.activeStep < this.progressStepsSetting.length) this.activeStep = this.activeStep + 1
    },

  }
}
</script>

<style lang="scss">

.product-progress-steps {
  max-width: 625px;
}

</style>
