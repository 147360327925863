<template>
	<div class="order-create__section">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_PersonalInformation'])"></div>
      {{$t('poshta_PersonalInformation.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_contactName'])"></div>
        <DefaultInput
            :label="$t('poshta_contactName.localization_value.value')"
            :type="'text'"
            v-bind:class="{'ui-no-valid': personalInfoTo.validation.personalName}"
            :errorTxt="$t(`${personalInfoTo.validationTranslate.personalName}.localization_value.value`)"
            :error="personalInfoTo.validation.personalName"
            v-model="personalInfoTo.information.personalName"
        />
      </div>

      <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_phone'])"></div>
        <DefaultInput
            :label="$t('common_phone.localization_value.value')"
            :type="'text'"
            v-bind:class="{'ui-no-valid': personalInfoTo.validation.personalPhone}"
            :errorTxt="$t(`${personalInfoTo.validationTranslate.personalPhone}.localization_value.value`)"
            :error="personalInfoTo.validation.personalPhone"
            v-model="personalInfoTo.information.personalPhone"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_email'])"></div>
        <DefaultInput
            :label="$t('common_email.localization_value.value')"
            :type="'text'"
            v-bind:class="{'ui-no-valid': personalInfoTo.validation.personalEmail}"
            :errorTxt="$t(`${personalInfoTo.validationTranslate.personalEmail}.localization_value.value`)"
            :error="personalInfoTo.validation.personalEmail"
            v-model="personalInfoTo.information.personalEmail"
        />
      </div>
    </div>

		<div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'poshta_deliveryAddress',
          'poshta_deliveryAddressTooltip'
          ])"></div>
      {{ $t('poshta_deliveryAddress.localization_value.value') }}
			<v-popover
							class="site-tooltip"
							offset="5"
							placement="top"
							trigger="hover"

			>
				<TooltipBtn
								class="tooltip-target"
				/>
				<template slot="popover">
					<p>
						<b>{{ $t('poshta_deliveryAddress.localization_value.value') }}</b>
					</p>
					<p>
            {{$t('poshta_deliveryAddressTooltip.localization_value.value')}}
					</p>
				</template>
			</v-popover>
		</div>

		<div class="order-create__section-sub">
			<div class="order-create__row custom-row">
				<div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_address'])"></div>
          <DefaultInput
              :label="$t('common_address.localization_value.value')"
              :type="'text'"
              v-bind:class="{'ui-no-valid': sendersAddressTo.validation.address}"
              :errorTxt="$t(`${sendersAddressTo.validationTranslate.address}.localization_value.value`)"
              :error="sendersAddressTo.validation.address"
              v-model="sendersAddressTo.delivery.address"
          />
				</div>
				<div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_city'])"></div>
          <DefaultInput
              :label="$t('common_city.localization_value.value')"
              :options="options"
              v-bind:class="{'ui-no-valid': sendersAddressTo.validation.city}"
              :errorTxt="$t(`${sendersAddressTo.validationTranslate.city}.localization_value.value`)"
              :error="sendersAddressTo.validation.city"
              v-model="sendersAddressTo.delivery.city"
          />
				</div>
				<div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_region'])"></div>
          <DefaultInput
              :label="$t('common_region.localization_value.value')"
              :options="options"
              v-bind:class="{'ui-no-valid': sendersAddressTo.validation.region}"
              :errorTxt="$t(`${sendersAddressTo.validationTranslate.region}.localization_value.value`)"
              :error="sendersAddressTo.validation.region"
              v-model="sendersAddressTo.delivery.region"
          />
				</div>
				<div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_country' ])"></div>
          <DefaultSelect
              :label="$t('common_country.localization_value.value')"
              :options="options"
              :optionsLabel="'label'"
              v-bind:class="{'ui-no-valid': sendersAddressTo.validation.country}"
              :errorTxt="serverError ? sendersAddressTo.validationTxt.country : $t(`${sendersAddressTo.validationTranslate.country}.localization_value.value`)"
              :error="sendersAddressTo.validation.country"
              :selected="sendersAddressTo.delivery.country"
              @change="changeCountry"
          />
				</div>
				<div class="order-create__col custom-col custom-col--16  custom-col--md-25 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_zipCode' ])"></div>
          <DefaultInput
              :label="$t('common_zipCode.localization_value.value')"
              :type="'text'"
              v-bind:class="{'ui-no-valid': sendersAddressTo.validation.zip}"
              :errorTxt="$t(`${sendersAddressTo.validationTranslate.zip}.localization_value.value`)"
              :error="sendersAddressTo.validation.zip"
              v-model="sendersAddressTo.delivery.zip"
          />
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
	import {radioButtonsFunc} from "../../../../../../mixins/creationPageMixins/radioButtonsFunc";
	import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
	import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
	import TooltipBtn from "../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import {filterCountriesUSA} from "../../../../../../services/filterCountries";

	export default {
		name: "StepThree",

		components:{
			DefaultSelect,
			DefaultInput,
			TooltipBtn,
		},

		mixins: [mixinDetictingMobile, radioButtonsFunc],

    props: {
      belposhta: Object,
      personalInfoTo: Object,
      sendersAddressTo: Object,
      serverError: {
        type: Boolean,
        default: false,
      }
    },

		data() {
			return {
				options: [{}],
			}
		},

    mounted() {
      this.$store.dispatch('fetchCountries').then(() => {
        let countries = this._.toArray(this.$store.getters.getCountries)
        filterCountriesUSA(countries)
        this.options = countries
      })
    },

		methods: {
      changeCountry(val) {
        if(val === '') {
          this.sendersAddressTo.setCountry('')
          return
        }
        this.sendersAddressTo.setCountry(val)
      },
		}

	}
</script>

<style scoped lang="scss">
	@import "../../../../../../scss/mixins/mixins";

	.toggle-block.small-size .toggle-block__details{
		width: 100%;
		max-width: 358px;
		min-height: 310px;

		@include for-768{
			margin-top: 20px;
			min-height: initial;
		}
	}

	.toggle-block__details-body{
		height: initial;
	}
</style>
