import { render, staticRenderFns } from "./BelposhtaEditing.vue?vue&type=template&id=3cb73326&scoped=true"
import script from "./BelposhtaEditing.vue?vue&type=script&lang=js"
export * from "./BelposhtaEditing.vue?vue&type=script&lang=js"
import style0 from "./BelposhtaEditing.vue?vue&type=style&index=0&id=3cb73326&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cb73326",
  null
  
)

export default component.exports