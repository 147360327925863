<template>
  <div class="fragment">
    <BelposhtaEditingPage
        :belposhta="belposhta"
        @edit="save"
    />
<!--        @getDeliveryPrice="getDeliveryPrice"-->

    <PayOrderPopup
        v-if="payOrderPopup"
        :orderPrice="orderPrice"
        :disableBtn="$store.getters.getNovaPoshtaBtn || $store.getters.getOrdersInternalBtn"
        @close="changePayOrderPopup(false)"
        @createLabel="save(false, true)"
    />
  </div>
</template>

<script>

  import {Belposhta} from "../../models/Belposhta";
  import BelposhtaEditingPage from "./BelposhtaEditingPage/BelposhtaEditingPage";
  import PayOrderPopup from "../../../../coreComponents/Popups/PayOrderPopup/PayOrderPopup";
  import {belposhtaOrdersMixins} from "../../../../../mixins/internalOrdersMixins/belposhtaOrdersMixin";

  export default {
    name: "BelposhtaEditing",

    components: {
      BelposhtaEditingPage,
      PayOrderPopup,
    },

    mixins: [belposhtaOrdersMixins],

    watch: {
      loadUserRoleAndAuthorizedData: function () {
        this.checkUserRightsToUkrposhta()
      },
    },

    data(){
      return{
        belposhta: new Belposhta(),

        payOrderPopup: false,

        orderPrice: -1,
      }
    },

    mounted() {
      if (this.loadUserRoleAndAuthorizedData) {
        this.checkUserRightsToUkrposhta()
      }

      this.belposhta.setId(this.$route.params.id)

      this.$store.dispatch('getOrdersInternalEdit', this.belposhta.getId()).then(() => {
        this.belposhta.setBelposhtaItem(this.$store.getters.getOrdersInternalItem.orderInternal)
      }).catch(error => this.createErrorLog(error))

    },

    methods: {
      checkUserRightsToUkrposhta(){
        if(this.isCurrentUserRoleUser && !this.isBelarus){
          this.$router.push(this.$store.getters.GET_PATHS.notFound)
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  @import "../../../../../scss/mixins/mixins";

  @include for-680{
    .order-create__footer-link{
      width: 100%;
    }
  }

</style>
