import {Belposhta} from "../../components/modules/BelposhtaModule/models/Belposhta";
import {internalOrdersMixin} from "./internalOrdersMixin";
import {setPersonalInfoMixin} from "../commonMixins/setPersonalInfoMixin";



export const belposhtaOrdersMixins = {

  mixins: [internalOrdersMixin, setPersonalInfoMixin],

  methods: {

    changeUser(user) {
      this.belposhta.setBelposhtaUser(user)

      // this.setPersonalInfo(
      //   user,
      //   userProperty,
      //   this.belposhta.data.PersonalInfoFrom,
      //   this.belposhta.data.SendersAddressFrom
      // )
    },

    changePayOrderPopup(val) {
      this.payOrderPopup = val
    },

    createLabel(id, fromTable = false) {
      this.$store.dispatch('createBelPoshtaLabel', id).then(response => {
        if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

          this.openNotify('success', 'common_notificationRecordCreated')

          if(fromTable) {
            this.$emit('reload')
            if(this.payOrderPopup) this.payOrderPopup = false
            return
          }

          this.belposhtaProformaCN22(id)

          this.$router.push(this.$store.getters.GET_PATHS.belposhta)

        } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
          let errors = response.response.data.errors;

          this.openNotify('error', {txtServer: errors[0]})
        } else {
          this.openNotify('error', 'common_notificationUndefinedError')
        }
      }).catch(error => this.createErrorLog(error))
    },

    // getDefaultWarehouse() {
    //   // get Warehouse for Ship to warehouse field
    //   const query = new this.Query();
    //   let url = '?';
    //   let myQuery = query
    //     .for('posts')
    //
    //   myQuery.where('warehouseCode', 'kiev01')
    //
    //   url = url + myQuery.url().split('?')[1]
    //   this.$store.dispatch('fetchWarehouses', url).then(() => {
    //     if(this.$store.getters.getWarehouses.length > 0) {
    //       this.belposhta.setShippingWarehouse(this.$store.getters.getWarehouses[0])
    //     }
    //   })
    // },

    // getDataExpress(id) {
    //   this.$store.dispatch('getExpress', id).then(() => {
    //     let express = this.$store.getters.getExpressItem
    //     if(this._.has(express, 'expressOrderInstance')){
    //       this.belposhta.data.package.setPackagingItem(express.expressOrderInstance.proform_packaging)
    //
    //       if(express.expressOrderInstance.warehouse){
    //         this.belposhta.setShippingWarehouse(express.expressOrderInstance.warehouse)
    //       } else {
    //         this.getDefaultWarehouse()
    //       }
    //
    //     }
    //   })
    // },

    // getDeliveryPrice() {
    //
    //   console.log('belposhta ordersInternal - getDeliveryPrice');
    //
    //   if(!this.belposhta.firstValidation()) return
    //
    //   let data = this.belposhta.prepareGetDeliveryPrice()
    //
    //   this.$store.dispatch('getBelposhtaDeliveryPrice', data).then(response => {
    //     if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
    //
    //       this.orderPrice = response.data.data.price
    //       this.changePayOrderPopup(true)
    //     } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
    //       let errors = response.response.data.errors;
    //
    //       console.log(errors);
    //     } else {
    //       this.openNotify('error', 'common_notificationUndefinedError')
    //     }
    //   })
    // },

    save(draft, edit){

      if(this.isAdmin && !this.belposhta.adminValidation()) {
        return
      }

      let data = this.belposhta.prepareData()

      console.log(123, data);

      if(edit){
        data = {
          data: this.belposhta.prepareData(),
          id: this.belposhta.getId()
        }
      }

      if(draft){
        let confirm = () => {
          this.saveOrderInternal(draft, edit, data, 'belposhta').then(status => {
            console.log(status);
            this.successSave(status)
          })

          this.$store.commit('setSaveInDraftsPopup', false)
          return true
        }

        this.saveInDraftsFunc(confirm)
      } else {
        this.saveOrderInternal(draft, edit, data, 'belposhta').then(response => {
          this.createLabel(response.id)

          return true
        })
      }
    },

    successSave(status) {
      if(!status) return

      // if(this.isCurrentUserRoleUser){
      //   this.$router.push(this.$store.getters.GET_PATHS.expressOrders)
      //   return
      // }

      this.$router.push(this.$store.getters.GET_PATHS.belposhta)
    },

    belposhtaProformaCN22(id){
      this.$store.dispatch('getOrdersInternalBelposhtaCN22', id).then((response) => {
        this.globalDownloadBase64File('application/xls', response.data.data.file, 'Customs declaration CN22.xls')
      })
    },

  },


}



export const belposhtaOrdersTableMixins = {

  mixins: [belposhtaOrdersMixins],

  methods: {
    getDeliveryPriceFromTable(id) {
      let belposhta = new Belposhta()

      console.log(belposhta, id);
      // this.$store.dispatch('getOrdersInternal', id).then(() => {


        // belposhta.setBelposhtaItem(this.$store.getters.getOrdersInternalItem.orderInternal)

        // let data = belposhta.prepareGetDeliveryPrice()
        //
        // this.$store.dispatch('getBelposhtaDeliveryPrice', data).then(response => {
        //   if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
        //
        //     this.orderPrice = response.data.data.price
        //     this.changePayOrderPopup(true)
        //   } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
        //     let errors = response.response.data.errors;
        //
        //     console.log(errors);
        //   } else {
        //     this.openNotify('error', 'common_notificationUndefinedError')
        //   }
        // })
      // })
    },

    openPoshtaLink(link){
      let pdfWindow = window.open("label")
      pdfWindow.document.write("<style type='text/css'>body {margin: 0; overflow: hidden}</style> <iframe width='100%' height='100%' src='"+ link +"'></iframe>")
    },

  }
}
