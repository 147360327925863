<template>
  <div class="fragment">

    <div class="order-create__title-horizontal"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
						'product_Product',
						'common_DeleteProduct',
					])"></div>
      <TitleHorizontal
          :value="$t('product_Product.localization_value.value') + ' #' + parseInt(index)"
          :rightBtn="true"
          :rightBtnType="'delete'"
          :rightBtnText="$t('common_DeleteProduct.localization_value.value')"
          @rightBtnClick="$emit('removeProduct')"
      />
    </div>

    <div class="order-create__product custom-row"
         @mouseover="isHovering = true"
         @mouseout="isHovering = false"
         :class="{hover: isHovering}"
    >

      <div class="order-create__product-col custom-col position-relative d-flex mb-0">
<!--        <div class="order-create__product-delete">-->
<!--            <span @click="$emit('removeProduct')">-->
<!--              <DeleteProductBtn/>-->
<!--            </span>-->
<!--        </div>-->

<!--        <DefaultInput-->
<!--            class="w-100"-->
<!--            :label="$t('poshta_productName.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            :numeric="true"-->
<!--            v-bind:class="{'ui-no-valid': belposhtaProduct.validation.name}"-->
<!--            :errorTxt="$t(`${belposhtaProduct.validationTranslate.name}.localization_value.value`)"-->
<!--            :error="belposhtaProduct.validation.name"-->
<!--            v-model="belposhtaProduct.product.name"-->
<!--        />-->

      </div>

      <div class="custom-col mb-0">
        <SimpleProductBlock
            :userId="belposhta.data.userId"
            :simpleProduct="belposhtaProduct"
            @removeProduct="belposhta.removeBelposhtaProduct(index)"
        />
      </div>



    </div>
  </div>
</template>

<script>
// import DeleteProductBtn from "../../../../../../coreComponents/DeleteProductBtn/DeleteProductBtn";
import TitleHorizontal from "../../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
// import InputQuantity from "../../../../../../UI/inputs/InputQuantity/InputQuantity";
// import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
// import InputSum from "../../../../../../UI/inputs/InputSum/InputSum";
import SimpleProductBlock from "../../../../../../coreComponents/SimpleProductBlock/SimpleProductBlock";

export default {
  name: "BelposhtaProduct",

  components: {
    // DeleteProductBtn,
    // InputQuantity,
    TitleHorizontal,
    // DefaultInput,
    // InputSum,
    SimpleProductBlock,
  },

  props: {
    belposhta: Object,
    belposhtaProduct: Object,
    index: Number,
  },

  data() {
    return {
      isHovering: false,
      options: [{}],
    }
  },

  mounted() {

  },

  methods: {
    // changeValueSum(val){
    //   this.belposhtaProduct.setProductPrice(val)
    // },
    //
    // changeValueQuantity(val) {
    //   this.belposhtaProduct.setProductCount(val)
    // },

  }
}
</script>

<style scoped>

</style>
