<template>
  <div class="fragment">
    <BelposhtaCreationUser
      :belposhta="belposhta"
      @create="save"
    />
  </div>
</template>

<script>
  import BelposhtaCreationUser from "./BelposhtaCreationUser/BelposhtaCreationUser";
  import {Belposhta} from "../../models/Belposhta";
  import {belposhtaOrdersMixins} from "../../../../../mixins/internalOrdersMixins/belposhtaOrdersMixin";

  export default {
    name: "BelposhtaCreation",
    components: {
      BelposhtaCreationUser,
    },

    mixins: [belposhtaOrdersMixins],

    watch: {
      loadUserRoleAndAuthorizedData: function (val) {
        if(val){
          this.getUserData()
        }
      },
    },

    data() {
      return {
        belposhta: new Belposhta(),
      }
    },

    created() {
      if (this.loadUserRoleAndAuthorizedData) {
        this.getUserData()
      }

      let transactionId = this.$route.params.id
      let transactionType = this.$route.params.typeTransaction

      if(transactionId && transactionType){
        this.getTransaction(transactionId, transactionType)
      }

    },

    methods: {
      getTransaction(transactionId, transactionType) {
        let item

        if(transactionType === this.belposhta.data.typesTransaction.payment) {
          this.$store.dispatch('getCusPayments', transactionId).then(() => {
            item = this.$store.getters.getCusPaymentsItem
            let paymentDetail = item.payment_transaction_detail
            let paymentDetailContacts = paymentDetail.payment_transaction_contact

            this.belposhta.setPersonalInfoTo({
              full_name: paymentDetailContacts.full_name,
              phone: paymentDetailContacts.phone,
              email: paymentDetailContacts.email,
            })
            this.belposhta.setSendersAddressTo({
              address: paymentDetailContacts.address,
              city: paymentDetailContacts.city,
              region: paymentDetailContacts.state,
              country:  {
                id: paymentDetailContacts.countryInstance.id,
                label: paymentDetailContacts.countryInstance.name
              },
              zip: paymentDetailContacts.zip_code,
            })

            setTimeout(() => {
              if(this.isAdmin)
                this.changeUser(item.user, 'user_personal_contact')
            }, 300)

          }).catch(error => this.createErrorLog(error))
        }

        if(transactionType === this.belposhta.data.typesTransaction.order) {

          this.$store.dispatch('getImportOrders', transactionId).then(() => {

            item = this.$store.getters.getImportOrdersItem

            this.belposhta.setPersonalInfoTo({
              full_name: item.customer_name,
              phone: item.original_phone,
              email: item.original_email
            })
            this.belposhta.setSendersAddressTo({
              address: item.original_address_line_1,
              address2: item.original_address_line_2,
              city: item.original_city,
              region: item.original_state,
              country: item.country ? {
                id: item.country.id,
                label: item.country.name
              } : null,
              zip: item.original_post_code,
            })

            setTimeout(() => {
              if(this.isAdmin)
                this.changeUser(item.user, 'user_personal_contact')
            }, 300)

          }).catch(error => this.createErrorLog(error))
        }

        this.belposhta.setPaymentTransactionId(transactionId)
        this.belposhta.setTypeTransaction(transactionType)

      },

      getUserData() {
        if(this.isCurrentUserRoleUser){
          if(!this.isBelarus){
            this.$router.push(this.$store.getters.GET_PATHS.notFound)
          }

          let userData = this.$store.getters.GET_COMMON_AUTHORIZED.user
          this.belposhta.setBelposhtaUser(userData)
        }
      },
    }

  }
</script>

<style scoped>

</style>
