<template>
  <div class="fragment">
    <BelposhtaTableUser
        :countFilterParams="countFilterParams"
        :filterGetParams="filterGetParams"
        @resetFilter="resetFilter"
        @showMore="showMore"
        @changeFilter="changeFilter"
        @reload="reload"
    />
  </div>
</template>

<script>
  import BelposhtaTableUser from "./BelposhtaTableUser/BelposhtaTableUser";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {BELPOCHTA_DELIVERY_SERVICE} from "../../../../../staticData/staticVariables";

  export default {
    name: "BelposhtaTable",
    components: {
      BelposhtaTableUser,
    },

    mixins: [routeFilter],

    watch: {
      loadUserAuthorizedData() {
        if(!this.isBelarus && !this.isAdmin) {
          this.$router.push(this.$store.getters.GET_PATHS.notFound)
        }
      }
    },

    data(){
      return {
        forPage: this.$store.getters.getOrdersInternalForPage,
        page: 1,
        onePage: 1,

        filterId: '',
        filterUserId: '',
        filterUserName: '',
        filterDate: [],
        filterPayPalTransaction: '',
        filterTrackingNumber: '',
        // filterShippingCost: '',

        filterRecipientName: '',
        filterRecipientPhone: '',
        filterRecipientEmail: '',
        filterRecipientAddress: '',
        filterRecipientCity: '',
        filterRecipientRegion: '',
        filterRecipientCountryId: '',
        filterRecipientZip: '',

        filterShipped: [],

        countFilterParams: 0,
        filterGetParams: {},
      }
    },


    mounted() {
      if(this.loadUserAuthorizedData && (!this.isBelarus && !this.isAdmin)) {
        this.$router.push(this.$store.getters.GET_PATHS.notFound)
      }

      this.filter()
    },

    beforeDestroy() {
      this.$store.commit('setOrdersInternal',{ordersInternal: []})
      this.$store.commit('setOrdersInternalCommonList',{ordersInternalCommonList: []})
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          id: this.$route.query.id,
          userId: this.$route.query.userId,
          userName: this.$route.query.userName,
          date: this.$route.query.date,
          payPalTransaction: this.$route.query.payPalTransaction,
          trackingNumber: this.$route.query.trackingNumber,
          // shippingCost: this.$route.query.shippingCost,
          recipientName: this.$route.query.recipientName,
          recipientPhone: this.$route.query.recipientPhone,
          recipientEmail: this.$route.query.recipientEmail,
          recipientAddress: this.$route.query.recipientAddress,
          recipientCity: this.$route.query.recipientCity,
          recipientRegion: this.$route.query.recipientRegion,
          recipientCountryId: this.$route.query.recipientCountryId,
          recipientZip: this.$route.query.recipientZip,
          shipped: this.$route.query.shipped,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextOrdersInternalPage', true)
        this.$store.dispatch('fetchOrdersInternal', url).then(() => {
          this.$store.commit('setNextOrdersInternalPage', false)
          this.$store.commit('setGlobalUpdateTable', true)
        })


        this.checkCountFilter(['userName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
            myQuery,
            {

              filterUserId: 'ointUserId',
              filterId: 'ointId',
              filterDate: 'ointBetweenCreatedAt',
              filterPayPalTransaction: 'byTransactionNumber',
              filterTrackingNumber: 'ointTrackingNumber',
              // filterShippingCost: 'ointShippingCost',
              filterRecipientName: 'ointRecipientFullName',
              filterRecipientPhone: 'ointRecipientPhone',
              filterRecipientEmail: 'ointRecipientEmail',
              filterRecipientAddress: 'ointRecipientAddress',
              filterRecipientCity: 'ointRecipientCity',
              filterRecipientRegion: 'ointRecipientState',
              filterRecipientCountryId: 'ointRecipientCountryId',
              filterRecipientZip: 'ointRecipientZip',
            },
        )

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
              'ointBetweenCreatedAt',
              date
          )
        }

        if (this.filterShipped.length > 0) {
          let date = this.generateDateForFilter(this.filterShipped, 'YYYY-MM-DD')

          myQuery.whereIn(
              'ointBetweenShippedAt',
              date
          )
        }


        myQuery.where('ointDeliveryServiceId', BELPOCHTA_DELIVERY_SERVICE.id)

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    }

  }
</script>

<style scoped>

</style>
