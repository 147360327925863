<template>
  <div class="order-create__section">
    <div class="order-create__title-horizontal"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_sendingDetails',])"></div>
      <TitleHorizontal
          :value="$t('poshta_sendingDetails.localization_value.value')"
          :rightBtn="rightEdit"
          :rightBtnType="'edit'"
          :rightBtnText="$t('common_edit.localization_value.value')"
          @rightBtnClick="$emit('changeStep', 1)"
      />
    </div>

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_PersonalInformation',])"></div>
      {{$t('poshta_PersonalInformation.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <div class="order-create__table block-table">
          <div class="block-table__row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_contactName',])"></div>
                {{$t('poshta_contactName.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="belposhta.data.PersonalInfoFrom.information.personalName"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_phone',])"></div>
                {{$t('common_phone.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="belposhta.data.PersonalInfoFrom.information.personalPhone"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_email',])"></div>
                {{$t('common_email.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="belposhta.data.PersonalInfoFrom.information.personalEmail"
                />
              </div>
            </div>
          </div>

          <div class="order-create__section-label section-label mt-2"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['poshta_SenderAddress'])"></div>
            {{$t('poshta_SenderAddress.localization_value.value')}}
          </div>

          <div class="block-table__row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_address',])"></div>
                {{$t('common_address.localization_value.value')}}
<!--                Address-->
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="belposhta.data.SendersAddressFrom.delivery.address"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_city',])"></div>
                {{$t('common_city.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="belposhta.data.SendersAddressFrom.delivery.city"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_zipCode',])"></div>
                {{$t('common_zipCode.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="belposhta.data.SendersAddressFrom.delivery.zip"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="order-create__title-horizontal"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['product_ProductDetails',])"></div>
      <TitleHorizontal
          :value="$t('product_ProductDetails.localization_value.value')"
          :rightBtn="rightEdit"
          :rightBtnType="'edit'"
          :rightBtnText="$t('common_edit.localization_value.value')"
          @rightBtnClick="$emit('changeStep', 2)"
      />
    </div>


    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">

        <div class="block-table__name block-table__name--mobile  mb-4"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['poshta_products',])"></div>
          {{$t('poshta_products.localization_value.value')}}
<!--          Products-->
        </div>

        <div class="site-table-wrap table-small"
             v-if="!isMobileFunc()"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
        >
          <div class="admin-edit" @click="editTranslate([
                'poshta_productName',
                'poshta_qTy',
                'poshta_perOne',
                'common_total'
              ])"></div>
          <table class="site-table table-small">

            <thead>
            <tr>
              <th width="100%">
                {{$t('poshta_productName.localization_value.value')}}
              </th>
              <th class="white-space-line" align="center">
                {{$t('poshta_qTy.localization_value.value')}}
              </th>
              <th class="white-space-line" align="center">
                {{$t('poshta_perOne.localization_value.value')}}, $
              </th>
              <th class="white-space-line" align="center">
                {{$t('common_total.localization_value.value')}}, $
              </th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(item, index) in belposhta.data.BelposhtaProduct"
                :key="index"
            >
              <td>
                <div>
                  <ValueHelper
                      :value="item.data.productObject.name"
                  />
                </div>
              </td>
              <td align="right">
                <ValueHelper
                    :value="item.data.count"
                />
              </td>
              <td align="right">
                <ValueHelper
                    :value="item.data.price"
                />
              </td>
              <td align="right">
                <ValueHelper
                    :value="item.data.totalSum"
                />
              </td>
            </tr>
            </tbody>
          </table>

        </div>

        <div class="site-table-mobile"
             v-if="isMobileFunc()"
        >
          <div class="site-table-mobile__inner custom-row">
            <div class="custom-col custom-col--50 custom-col--sm-100"
                 v-for="(item, index) in belposhta.data.BelposhtaProduct"
                 :key="index"
            >

              <div class="site-table-mobile__item">
                <div class="site-table-mobile__head">
                  <span>
                    {{ item.data.productObject.name }}
                  </span>
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col custom-col--33">
                    {{$t('poshta_qTy.localization_value.value')}}
                    <span>
                       <ValueHelper
                           :value="item.data.count"
                       />
                    </span>
                  </div>
                  <div class="site-table-mobile__piece custom-col custom-col--33">
                    {{$t('poshta_perOne.localization_value.value')}}, $
                    <span>
                      <ValueHelper
                          :value="item.data.price"
                      />
                    </span>
                  </div>
                  <div class="site-table-mobile__piece custom-col custom-col--33">
                    {{$t('common_total.localization_value.value')}}, $
                    <span>
                      <ValueHelper
                          :value="item.data.totalSum"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <div class="order-create__table">
          <div class="block-table__name"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['poshta_shippingInformation',])"></div>
            {{$t('poshta_shippingInformation.localization_value.value')}}
          </div>

          <div class="custom-row">
            <div class="custom-col custom-col--16 custom-col--sm-50"
              v-if="belposhta.data.packaging.packaging.name.package_name"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['packaging_Packaging',])"></div>
                {{$t('packaging_Packaging.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="belposhta.data.packaging.packaging.name.package_name"
                />
              </div>
            </div>
            <div class="custom-col custom-col--16 custom-col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_weightKg',])"></div>
                {{$t('common_weightKg.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="belposhta.data.packaging.packaging.weight"
                />
              </div>
            </div>
            <div class="custom-col custom-col--16 custom-col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_heightCm',])"></div>
                {{$t('common_heightCm.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="belposhta.data.packaging.packaging.height"
                />
              </div>
            </div>
            <div class="custom-col custom-col--16 custom-col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_width, cm',])"></div>
                {{$t('common_width, cm.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="belposhta.data.packaging.packaging.width"
                />
              </div>
            </div>
            <div class="custom-col custom-col--16 custom-col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_lengthCm',])"></div>
                {{$t('common_lengthCm.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="belposhta.data.packaging.packaging['length']"
                />
              </div>
            </div>

            <div class="custom-col custom-col--16 custom-col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_ShippingDate',])"></div>
                {{$t('poshta_ShippingDate.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="belposhta.data.shippingDate"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="order-create__title-horizontal"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_recipient',])"></div>
      <TitleHorizontal
          :value="$t('poshta_recipient.localization_value.value')"
          :rightBtn="rightEdit"
          :rightBtnType="'edit'"
          :rightBtnText="$t('common_edit.localization_value.value')"
          @rightBtnClick="$emit('changeStep', 3)"
      />
    </div>

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_PersonalInformation',])"></div>
      {{$t('poshta_PersonalInformation.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <div class="order-create__table block-table">
          <div class="block-table__row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_contactName',])"></div>
                {{$t('poshta_contactName.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="belposhta.data.PersonalInfoTo.information.personalName"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_phone',])"></div>
                {{$t('common_phone.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="belposhta.data.PersonalInfoTo.information.personalPhone"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_email',])"></div>
                {{$t('common_email.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="belposhta.data.PersonalInfoTo.information.personalEmail"
                />
              </div>
            </div>
          </div>

          <div class="order-create__section-label section-label mt-2"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['poshta_deliveryAddress',])"></div>
            {{$t('poshta_deliveryAddress.localization_value.value')}}
          </div>

          <div class="block-table__row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_address',])"></div>
                {{$t('common_address.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="belposhta.data.SendersAddressTo.delivery.address"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_city',])"></div>
                {{$t('common_city.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="belposhta.data.SendersAddressTo.delivery.city"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_region',])"></div>
                {{$t('common_region.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="belposhta.data.SendersAddressTo.delivery.region"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_country',])"></div>
                {{$t('common_country.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="belposhta.data.SendersAddressTo.delivery.country.label"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_zipCode',])"></div>
                {{$t('common_zipCode.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="belposhta.data.SendersAddressTo.delivery.zip"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import TitleHorizontal from "../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
import ValueHelper from "../../../../../coreComponents/ValueHelper/ValueHelper";

export default {
  name: "StepFour",
  components: {
    TitleHorizontal,
    ValueHelper,
  },

  mixins: [mixinDetictingMobile],

  props: {
    belposhta: Object,
    rightEdit: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {

    }
  },

  methods: {

  },
}
</script>

<style scoped>

</style>

