<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_order', 'poshta_trackingNumber', 'poshta_PayPalTransaction', 'poshta_Created' ])"></div>
      <CardLeftBlock
          :name="$t('poshta_order.localization_value.value')"
          :value="`#${belposhta.data.id}`"
          :backgroundImage="'orders'"
      >
        <template slot="body">
          <div class="d-flex  mt-3"
               v-if="belposhta.data.trackingNumber !== ''"
          >
            <div class="card-detail-left__name">
              {{ $t('poshta_trackingNumber.localization_value.value') }}
            </div>
            <div class="card-detail-left__date ml-2">
              <ValueHelper
                  :value="belposhta.data.trackingNumber"
              />
            </div>
          </div>
          <div class="d-flex"
               v-if="belposhta.data.transaction !== ''"
          >
            <div class="card-detail-left__name">
              {{ $t('poshta_PayPalTransaction.localization_value.value') }}
            </div>
            <div class="card-detail-left__date ml-2">
              <ValueHelper
                  :value="belposhta.data.transaction"
              />
            </div>
          </div>


        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_backTo',])"></div>
                <router-link :to="$store.getters.GET_PATHS.belposhta" class="order-create__head-back">
                  <LinkBack
                      :value="$t('poshta_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['poshta_editBelposhta'])"></div>
              {{ $t('poshta_orderBelposhta.localization_value.value') }}
            </div>
          </div>
        </template>
        <template slot="body">

          <StepFour
                    :belposhta="belposhta"
                    :rightEdit="false"
          />

        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link"
                 v-if="$store.getters.getOrdersInternalItem.orderInternal &&
                  ($store.getters.getOrdersInternalItem.orderInternal.order_internal_status_id !== INTERNAL_STATUSES.COMPLETE.id)"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_deleteOrder '])"></div>

              <span class="site-link site-link--alt"
                    @click="removeOrderInternal(belposhta.data.id, INTERNAL_DELIVERY_SERVICES.BELPOCHTA_DELIVERY_SERVICE.id)"
              >
                {{ $t('common_deleteOrder.localization_value.value') }}
              </span>
            </div>


            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_createACopy'])"></div>
              <MainButton
                  class="order-create__footer-btn-i wfc"
                  @click.native="copy"
                  :value="$t('common_createACopy.localization_value.value')"
                  v-bind:class="{'disabled-btn' : $store.getters.getOrdersInternalBtn}"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>

  </div>
</template>

<script>

  import {Belposhta} from "../../models/Belposhta";
  import {belposhtaOrdersMixins} from "../../../../../mixins/internalOrdersMixins/belposhtaOrdersMixin";
  import CardLeftBlock from "../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import CardRightBlock from "../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../UI/buttons/LinkBack/LinkBack";
  import StepFour from "../components/StepFour/StepFour";
  import {INTERNAL_DELIVERY_SERVICES, INTERNAL_STATUSES} from "../../../../../staticData/staticVariables";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";

  export default {
    name: "BelposhtaShow",

    components: {
      MainButton,
      LinkBack,
      CardRightBlock,
      CardLeftBlock,
      StepFour,
      ValueHelper,
    },

    mixins: [belposhtaOrdersMixins],

    data(){
      return{
        belposhta: new Belposhta(),

        INTERNAL_DELIVERY_SERVICES: INTERNAL_DELIVERY_SERVICES,
        INTERNAL_STATUSES: INTERNAL_STATUSES,

        payOrderPopup: false,

        orderPrice: -1,
      }
    },

    mounted() {
      this.belposhta.setId(this.$route.params.id)

      this.$store.dispatch('getOrdersInternal', this.belposhta.getId()).then(() => {
        this.belposhta.setBelposhtaItem(this.$store.getters.getOrdersInternalItem.orderInternal)
      }).catch(error => this.createErrorLog(error))

    },

    methods: {
      copy() {
        this.copyOrder(this.belposhta.getId()).then(() => {
          this.$router.push(this.$store.getters.GET_PATHS.belposhta)
        })
      }
    },
  }
</script>

<style scoped lang="scss">
  @import "../../../../../scss/mixins/mixins";

  @include for-680{
    .order-create__footer-link{
      width: 100%;
    }
  }

</style>
