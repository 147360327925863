<template>
  <div class="order-create__section">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_ProductInformationMaxItem'])"></div>
      {{$t('poshta_ProductInformationMaxItem.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--50 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_ProductInformation', 'poshta_OneShipment'])"></div>
        <WarningStatic class="mw-100 w-100">
          <template slot="title">
            {{$t('poshta_ProductInformation.localization_value.value')}}
          </template>
          <template slot="text">
            {{$t('poshta_OneShipmentBel.localization_value.value')}}
          </template>
        </WarningStatic>
      </div>
    </div>

    <BelposhtaProduct
        v-for="(item, index) in belposhta.data.BelposhtaProduct"
        :key="index"
        :belposhtaProduct="item"
        :belposhta="belposhta"
        :index="index + 1"
        @removeProduct="belposhta.removeBelposhtaProduct(index)"
    />

    <div class="order-create__product custom-row"
      v-if="belposhta.data.BelposhtaProduct.length < 4"
    >
      <div class="order-create__product-col custom-col custom-col--md-100 mb-0">
        <div class="order-create__product-btn add-product-btn-wrap mb-2">
        <span class="order-create__product-btn-i add-product-btn site-link"
              @click="belposhta.addBelposhtaProduct()"
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_AddProduct'])"></div>
          +  {{$t('common_AddProduct.localization_value.value')}}
        </span>
        </div>
      </div>
    </div>

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['packaging_Packaging', ])"></div>
      {{$t('packaging_Packaging.localization_value.value')}}
    </div>

    <PackagingBlock
        :userId="belposhta.data.userId"
        :packaging="belposhta.data.packaging"
    />

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_shippingInformation', ])"></div>
      {{$t('poshta_shippingInformation.localization_value.value')}}
    </div>


    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_contactName', ])"></div>
        <DatePickerDefault
                :label="$t('poshta_ShippingDate.localization_value.value')"
                :value="belposhta.data.shippingDate"
                v-bind:class="{'ui-no-valid': belposhta.validation.shippingDate}"
                :errorTxt="serverError ? belposhta.validationTxt.shippingDate : $t(`${belposhta.validationTranslate.shippingDate}.localization_value.value`)"
                :error="belposhta.validation.shippingDate"
        >
          <template slot="body">
            <date-picker
                    v-model="belposhta.data.shippingDate"
                    valueType="format"
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :readonly="true"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>
    </div>

  </div>
</template>

<script>
  // import DeleteProductBtn from "../../../../../../../coreComponents/DeleteProductBtn/DeleteProductBtn";
  // import DefaultSelect from "../../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  // import InputQuantity from "../../../../../../../UI/inputs/InputQuantity/InputQuantity";
  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import DatePickerDefault from "../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  // import InputSum from "../../../../../../../UI/inputs/InputSum/InputSum";
  // import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  // import TitleHorizontal from "../../../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
  import BelposhtaProduct from "./BelposhtaProduct/BelposhtaProduct";
  // import SearchSelect from "../../../../../UI/selectiones/SearchSelect/SearchSelect";
  // import AddToProfileIco from "../../../../../../../public/img/UI-group/add-to-profile-ico.svg?inline";
  import PackagingBlock from "../../../../../coreComponents/PackagingBlock/PackagingBlock";
  import WarningStatic from "../../../../../UI/alerts/WarningStatic/WarningStatic";

  export default {
    name: "StepTwo",
    components:{
      WarningStatic,
      PackagingBlock,
      BelposhtaProduct,
      // DeleteProductBtn,
      // DefaultSelect,
      // InputQuantity,
      DatePickerDefault,
      DatePicker,
      // InputSum,
      // DefaultInput,
      // TitleHorizontal,
      // SearchSelect,
      // AddToProfileIco,
      // SimpleProductBlock,
    },

    mixins: [mixinDetictingMobile],

    props: {
      belposhta: Object,
      belposhtaPackaging: Object,
      serverError: {
        type: Boolean,
        default: false,
      },
      hideAddProfile: {
        type: Boolean,
        default: false
      },
    },


    data(){
      return{
        options: [{}],
        date: '',
      }
    },

    mounted() {

    },

    methods: {

      changeSetPopup(val){
        this.isModalSetPopup = val
      },

      changeValueSum(data){
        this.valueSum = data
      },

    },
  }
</script>

<style scoped>

</style>
