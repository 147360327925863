<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <div class="table-card__item-number">
          #{{item.id}}
        </div>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('poshta_Created.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>


          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                      :item="item"
                      :mobile="true"
              />
            </div>
          </div>

          <div class="table-card__item-col custom-col custom-col">
            <div class="table-card__item-label">
              {{$t('poshta_PayPalTransaction.localization_value.value')}}
            </div>
            <div class="table-card__item-info d-flex align-items-center">
              <ValueHelper
                      v-if="item.payment_transaction"
                      :deep="'payment_transaction.transaction_id'"
                      :value="item"
              />
              <ValueHelper
                      v-else
                      :deep="'order_external.payment_transaction.transaction_id'"
                      :value="item"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col">
            <div class="table-card__item-label">
              {{$t('poshta_trackingNumber.localization_value.value')}}
            </div>
            <div class="table-card__item-info d-flex align-items-center">
              <InternalTrackingNumber
                      :item="item"
                      @reload="$emit('reload')"
              />
            </div>
          </div>

          <div class="table-card__item-col custom-col ">
            <div class="table-card__item-label">
              {{$t('poshta_recipientContactName.localization_value.value')}}
            </div>
            <div class="table-card__item-info d-flex align-items-center">
              <div class="d-flex align-items-center">
                <ValueHelper
                        :value="item.recipient_full_name"
                />
              </div>
            </div>
          </div>

          <div class="table-card__item-col custom-col ">
            <div class="table-card__item-label">
              {{$t('poshta_recipientAddress.localization_value.value')}}
            </div>
            <div class="table-card__item-info d-flex align-items-center">
              <div class="d-flex align-items-center">
                <ValueHelper
                        :value="item.recipient_address"
                />
              </div>
            </div>
          </div>

          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('poshta_itemNames.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item.products[0].name"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('poshta_Shipped.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.shipped_at | moment("DD MMM, YYYY") }}
            </div>
          </div>

        </div>
        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i"
               v-if="(item.order_internal_status_id !== INTERNAL_STATUSES.COMPLETE.id) ||
                              $store.getters.getIsAdminRights === 'admin'"
          >
            <LinkButton
                    :value="$t('common_edit.localization_value.value')"
                    :type="'edit'"
                    :link="$store.getters.GET_PATHS.belposhta + '/' + item.id"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="(item.order_internal_status_id !== INTERNAL_STATUSES.COMPLETE.id) ||
                            $store.getters.getIsAdminRights === 'admin'"
          >
            <LinkButton
                    :value="$t('common_delete.localization_value.value')"
                    :type="'delete'"
                    @click.native="$emit('removeOrderInternal')"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="(item.order_internal_status_id !== INTERNAL_STATUSES.COMPLETE.id) ||
                            $store.getters.getIsAdminRights === 'admin'"
          >
            <LinkButton
                    :value="$t('common_copy.localization_value.value')"
                    :type="'copy'"
                    @click.native="$emit('copyOrder')"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="(item.order_internal_status_id !== INTERNAL_STATUSES.COMPLETE.id)"
          >
            <LinkButton
                    :value="$t('common_create.localization_value.value')"
                    :type="'dog'"
                    @click.native="$emit('createLabel')"
                    :disabled="$store.getters.getBelPoshtaBtn"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="(item.order_internal_status_id === INTERNAL_STATUSES.COMPLETE.id)"
          >
            <LinkButton
                    :value="$t('poshta_customsDeclarationCN22.localization_value.value')"
                    :type="'docs'"
                    @click.native="$emit('belposhtaProformaCN22')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {INTERNAL_STATUSES} from "../../../../../../../../staticData/staticVariables";
  import InternalTrackingNumber
    from "../../../../../../../coreComponents/TableComponents/InternalTrackingNumber/InternalTrackingNumber";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";

  export default {
    name: "BelposhtaTableMobile",

    components: {
      TableUserColumn,
      ValueHelper,
      LinkButton,
      InternalTrackingNumber,
    },

    props: {
      item: Object,
    },

    data() {
      return {
        INTERNAL_STATUSES: INTERNAL_STATUSES,
        show: false,
      }
    }

  }
</script>

<style scoped>

</style>
