import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {PersonalInformation} from "../../../globalModels/PersonalInformation";
import {Delivery} from "../../../globalModels/Delivery";
import {
  BELPOCHTA_DELIVERY_SERVICE,
} from "../../../../staticData/staticVariables";
import {Packaging} from "../../../globalModels/Packaging";
import {SimpleProduct} from "../../../globalModels/SimpleProduct";

const moment = require('moment')
const _ = require('lodash');

export function Belposhta() {

  this.checkValid = checkValid

  this.data = {
    userId: -1,
    user: {},
    type: '',
    id: '',

    paymentTransactionId: '',
    typeTransaction: '',
    typesTransaction: {
      order: 'order',
      payment: 'payment',
    },



    senderPersonalInfo: {},
    PersonalInfoFrom: new PersonalInformation(),
    SendersAddressFrom: new Delivery(),

    BelposhtaProduct: [new SimpleProduct()],

    packaging: new Packaging(),

    shippingPackaging: '',
    shippingDate: '',


    shippingCost: '',
    trackingNumber: '',

    transaction: '',

    PersonalInfoTo: new PersonalInformation(),
    SendersAddressTo: new Delivery(),
  }

  this.validation = {
    shippingDate: false,
    shippingCost: false,
  }

  this.validationTranslate = {
    shippingDate: '',
    shippingCost: '',
  }

  this.validationTxt = {
    shippingDate: false,
    shippingCost: false,
  }

}


/*****************   SETTERS   ******************/

Belposhta.prototype.setType = function (val) {
  this.data.type = val;
}


Belposhta.prototype.setUserId = function (val) {
  this.data.userId = val
}

Belposhta.prototype.setId = function (val) {
  this.data.id = val;
}

Belposhta.prototype.setPaymentTransactionId = function (val) {
  this.data.paymentTransactionId = val;
}
Belposhta.prototype.setTypeTransaction = function (val) {
  this.data.typeTransaction = val;
}

Belposhta.prototype.setShippingPackaging = function (val) {
  this.data.shippingPackaging = val;
}

Belposhta.prototype.setShippingDate = function (val) {
  this.data.shippingDate = val;
}

Belposhta.prototype.setTrackingNumber = function (val) {
  this.data.trackingNumber = val;
}

Belposhta.prototype.setShippingCost = function (val) {
  this.data.shippingCost = val;
}

Belposhta.prototype.setTransaction = function (val) {
  this.data.transaction = val;
}

Belposhta.prototype.setPersonalInfoTo = function (val) {
  this.data.PersonalInfoTo.setPersonalItem({
    personalName: val.full_name,
    personalPhone: val.phone,
    personalEmail: val.email
  })
}

Belposhta.prototype.setSendersAddressTo = function (val) {
    this.data.SendersAddressTo.setDeliveryItem({
    address: val.address,
    address2: val.address2,
    city: val.city,
    region: val.region,
    country: val.country ? {
      id: val.country.id,
      label: val.country.label
    } : null,
    zip: val.zip
  })
}

Belposhta.prototype.setBelposhtaUser = function (user) {
  let userInformation

  this.data.user = user

  this.setUserId(user.id)

  if(user?.contacts){
    userInformation = _.first(user?.contacts)
  } else {
    userInformation = user?.user_personal_contact
  }

  this.writingDataCurrentUser(userInformation)
}

Belposhta.prototype.setBelposhtaItem = function (val) {

  let data = this.data

  if (val.user) {
    this.setBelposhtaUser(val.user)
  }



  if (val.products) {

    let simpleProduct = []

    val.products.map((item) => {
      let itemSimpleProduct = new SimpleProduct()
      itemSimpleProduct.setItem(item)

      simpleProduct.push(itemSimpleProduct)
    })

    this.data.BelposhtaProduct = simpleProduct
  }


  data.PersonalInfoFrom.setPersonalItem({
    personalName: val.sender_full_name,
    personalPhone: val.sender_phone ? val.sender_phone.replace(/[^\d]/g, '') : '',
    personalEmail: val.sender_email,
  })

  data.SendersAddressFrom.setDeliveryItem({
    address: val.sender_address,
    city: val.sender_city,
    country: val.sender_country ? {
      id: val.sender_country.id,
      label: val.sender_country.name,
    } : '',
    zip: val.sender_zip,
  })


  this.setShippingDate(
      val["shipped_at"] ? moment(val["shipped_at"]).format('YYYY-MM-DD') : ''
  )

  data.packaging.setPackagingItem({
    weight: parseFloat(val.weight).toFixed(2),
    height: parseFloat(val.height).toFixed(2),
    width: parseFloat(val.width).toFixed(2),
    length: parseFloat(val['length']).toFixed(2),
  })

  data.PersonalInfoTo.setPersonalItem({
    personalName: val.recipient_full_name,
    personalPhone: val.recipient_phone ? val.recipient_phone.replace(/[^\d]/g, '') : '',
    personalEmail: val.recipient_email,
  })

  data.SendersAddressTo.setDeliveryItem({
    address: val.recipient_address,
    city: val.recipient_city,
    region: val.recipient_state,
    country: val.recipient_country ? {
      id: val.recipient_country.id,
      label: val.recipient_country.name,
    } : '',
    zip: val.recipient_zip,
  })

  this.setShippingCost(val["shipping_cost"] ? val["shipping_cost"] : '')
  this.setTrackingNumber(val["tracking_number"] ? val["tracking_number"] : '')

  console.log(111111, val);

  let valTransaction = '';
  if(val.payment_transaction) valTransaction = val.payment_transaction.transaction_id
  if(val.order_external) valTransaction = val.order_external.payment_transaction.transaction_id

  this.setTransaction(valTransaction)

}


/*****************   GETTERS   ******************/
Belposhta.prototype.getType = function () {
  return this.data.type
}

Belposhta.prototype.getId = function () {
  return this.data.id
}

Belposhta.prototype.getPaymentTransactionId = function () {
  return this.data.paymentTransactionId
}

Belposhta.prototype.getTypeTransaction = function () {
  return this.data.typeTransaction
}

Belposhta.prototype.getUserId = function () {
  return this.data.userId
}

Belposhta.prototype.getShippingPackaging = function () {
  return this.data.shippingPackaging;
}

Belposhta.prototype.getShippingDate = function () {
  return this.data.shippingDate;
}

Belposhta.prototype.getShippingCost = function () {

  console.log(66666666);
  return this.data.shippingCost;
}
Belposhta.prototype.getTrackingNumber = function () {
  return this.data.trackingNumber;
}

Belposhta.prototype.getSenderPersonalInfo = function () {
  return this.data.senderPersonalInfo;
}

Belposhta.prototype.getTransaction = function () {
  return this.data.transaction;
}


/*******************   FUNCTIONS   *************/

Belposhta.prototype.addBelposhtaProduct = function () {

  if (this.data.BelposhtaProduct.length > 3) return

  this.data.BelposhtaProduct.push(new SimpleProduct())

  return this.data.BelposhtaProduct.length
}

Belposhta.prototype.removeBelposhtaProduct = function (index) {

  if (this.data.BelposhtaProduct.length <= 1) return

  this.data.BelposhtaProduct.splice(index, 1)

  return this.data.BelposhtaProduct.length
}

Belposhta.prototype.writingDataCurrentUser = function (userInfo) {

  let data = this.data

  data.PersonalInfoFrom.setPersonalItem({
    personalName: userInfo["user_full_name"],
    personalPhone: userInfo.phone ? userInfo.phone.replace(/[^\d]/g, '') : '',
    personalEmail: userInfo.email,
  })

  data.SendersAddressFrom.setDeliveryItem({
    address: userInfo.address,
    city: userInfo.city,
    country: userInfo.country ? {
      id: userInfo.country.id,
      label: userInfo.country.name,
    } : '',
    zip: userInfo.zip,
  })
}

/******************  VALIDATIONS  *****************/


Belposhta.prototype.firstValidation = function () {

  let data = this.data

  let personalInfoValid = data.PersonalInfoFrom.personalInfoPartsValidate({
    personalName: true,
    phoneOptional: true,
    emailOptional: true,
  })

  let senderAddressValid = data.SendersAddressFrom.deliveryInfoPartialValidate({
    address: true,
    city: true,
    zip: true,
  })

  return (personalInfoValid && senderAddressValid)


}

Belposhta.prototype.secondValidation = function () {

  let data = this.data

  let validationItems = {
    shippingDate: data.shippingDate,
  }

  let validationOptions = {
    shippingDate: {type: ['empty']}
  }

  let belposhtaValid = true

  data.BelposhtaProduct.map(item => {
    if(!item.dataValidation({
      productObject: true,
      count: true,
      price: true,
    }))
      belposhtaValid = false
  })


  let packagingValid = data.packaging.packagingPartialValidate({
    weight: true,
    height: true,
    width: true,
    packageLength: true,
  })


  return (this.checkValid(validationItems, validationOptions) && belposhtaValid && packagingValid)
}

Belposhta.prototype.thirdValidation = function () {
  let data = this.data



  let personalInfoValid = data.PersonalInfoTo.personalInfoPartsValidate({
    personalName: true,
    phoneOptional: true,
    emailOptional: true,
  })

  let senderAddressValid = data.SendersAddressTo.deliveryInfoPartialValidate({
    address: true,
    city: true,
    region: true,
    country: true,
    zip: true,
    latin: true,
  })

  return (personalInfoValid && senderAddressValid)
}

Belposhta.prototype.adminValidation = function () {
  // let data = this.data

  // let validationItems = {
  //   shippingCost: data.shippingCost,
  // }
  //
  // let validationOptions = {
  //   shippingCost: {type: ['numeric']}
  // }

  return (
      // this.checkValid(validationItems, validationOptions) &&
      this.firstValidation() &&
      this.secondValidation() &&
      this.thirdValidation()
  )
}


/******************  PREPARE DATA  *****************/

Belposhta.prototype.prepareData = function () {
  let poshta = this.data
  let packaging = this.data.packaging.preparePackagingItem()

  let personalInfoFrom = this.data.PersonalInfoFrom.preparePersonalInformation()
  let sendersAddressFrom = this.data.SendersAddressFrom.delivery
  let personalInfoTo = this.data.PersonalInfoTo.preparePersonalInformation()
  let sendersAddressTo = this.data.SendersAddressTo.delivery


  let items = []

  this.data.BelposhtaProduct.map((item) => {
    items.push({
      'name': item.data.productObject.name,
      'price': item.data.price,
      'quantity': item.data.count,
      'total': item.data.totalSum,
      'hs_code': item.data.hSCode,
    })
  })

  let data = {
    "delivery_service_id": BELPOCHTA_DELIVERY_SERVICE.id,

    "shipped_at": poshta.shippingDate,

    "weight": packaging.weight,
    "length": packaging['length'],
    "width": packaging.width,
    "height": packaging.height,

    "volume_weight": packaging.volumeWeight,
    "volume_general": packaging.volumeGeneral,

    "sender_country_id": sendersAddressFrom.country.id,
    "sender_full_name": personalInfoFrom.personalName,
    "sender_address": sendersAddressFrom.address,
    "sender_zip": sendersAddressFrom.zip,
    "sender_city": sendersAddressFrom.city,
    "sender_phone": personalInfoFrom.personalPhone,
    "sender_email": personalInfoFrom.personalEmail,

    "recipient_country_id": sendersAddressTo.country.id,
    "recipient_full_name": personalInfoTo.personalName,
    "recipient_address": sendersAddressTo.address,
    "recipient_zip": sendersAddressTo.zip,
    "recipient_city": sendersAddressTo.city,
    "recipient_state": sendersAddressTo.region,
    "recipient_phone": personalInfoTo.personalPhone.replace(/[^\d]/g, ''),
    "recipient_email": personalInfoTo.personalEmail,

    "items": items,

    "user_id": poshta.userId,


    "shipping_cost": this.getShippingCost() ? parseFloat(this.getShippingCost()).toFixed(2) : null,
    "tracking_number": this.getTrackingNumber() ? this.getTrackingNumber() : null
  }

  if(poshta.paymentTransactionId !== '' && poshta.typeTransaction !== ''){
    switch (poshta.typeTransaction) {
      case poshta.typesTransaction.order:
        data['order_external_id'] = poshta.paymentTransactionId
        break
      case poshta.typesTransaction.payment:
        data['payment_transaction_id'] = poshta.paymentTransactionId
        break
    }
  }


  return data
}
