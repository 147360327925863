import { render, staticRenderFns } from "./BelposhtaTableMobile.vue?vue&type=template&id=63bfed92&scoped=true"
import script from "./BelposhtaTableMobile.vue?vue&type=script&lang=js"
export * from "./BelposhtaTableMobile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63bfed92",
  null
  
)

export default component.exports