<template>
  <div>
    <BelposhtaHead
        :countFilterParams="countFilterParams"
        :showFilter="showFilter"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @reload="$emit('reload')"
        @selectAll="selectAll"
    />

    <div class="table-filter-wrap">

      <BelposhtaFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <BelposhtaTable
          :countFilterParams="countFilterParams"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @updateTable="$emit('updateTable')"
          @reload="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>


  import BelposhtaHead from "./BelposhtaHead/BelposhtaHead";
  import BelposhtaFilter from "./BelposhtaFilter/BelposhtaFilter";
  import BelposhtaTable from "./BelposhtaTable/BelposhtaTable";

  export default {
    name: "BelposhtaTableUser",

    components: {
      BelposhtaHead,
      BelposhtaFilter,
      BelposhtaTable,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data(){
      return{
        showFilter: false,
        selectedNow: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      selectAll(val) {
        this.selectedNow = val
      },
    }
  }
</script>

<style scoped>

</style>
