
<template>
  <div class="table-filter-wrap">
    <template v-if="$store.getters.getOrdersInternal.length > 0 && $store.getters.getOrdersInternalLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'poshta_number',
          'poshta_Created',
          'common_user',
          'poshta_PayPalTransaction',
          'poshta_trackingNumber',
          'poshta_recipientContactName',
          'poshta_recipientAddress',
          'poshta_itemNames',
          'poshta_Shipped',
          'common_manage',
          'common_edit',
          'common_delete',
          'common_create',
          'poshta_customsDeclarationCN22',
        ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{$t('poshta_number.localization_value.value')}}</th>
            <th>{{$t('poshta_Created.localization_value.value')}}</th>
            <th v-if="isAdmin"> {{$t('common_user.localization_value.value')}}</th>
            <th style="white-space: break-spaces;">{{$t('poshta_PayPalTransaction.localization_value.value')}}</th>
            <th>{{$t('poshta_trackingNumber.localization_value.value')}}</th>
            <th>{{$t('poshta_recipientContactName.localization_value.value')}}, $</th>
            <th>{{$t('poshta_recipientAddress.localization_value.value')}}</th>
            <th>{{$t('poshta_itemNames.localization_value.value')}}</th>
            <th>{{$t('poshta_Shipped.localization_value.value')}}</th>
            <th width="100%" v-if="$store.getters.getIsAdminRights === 'user'"></th>
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getOrdersInternal" :key="index">
            <td>
              <div class="table-row">
                <router-link
                    class="table-link btn-style"
                    :to="$store.getters.GET_PATHS.belposhta + '/show/' + item.id"
                >
                  #{{item.id}}
                </router-link>
              </div>
            </td>
            <td>
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </td>

            <td v-if="isAdmin">
              <TableUserColumn
                      :item="item"
              />
            </td>

            <td>
              <ValueHelper
                  v-if="item.payment_transaction"
                  :deep="'payment_transaction.transaction_id'"
                  :value="item"
              />
              <ValueHelper
                  v-else
                  :deep="'order_external.payment_transaction.transaction_id'"
                  :value="item"
              />
            </td>

            <td>

              <InternalTrackingNumber
                :item="item"
                @reload="$emit('reload')"
              />

            </td>

            <td>
<!--              <ValueHelper-->
<!--                  :value="item.recipient_full_name"-->
<!--              />-->
              <TooltipTextHelper
                  :text="item.recipient_full_name"
                  :paragraph="item.recipient_full_name"
              />
            </td>
            <td>
              <div class="d-flex align-items-center">
<!--                <BelposhtaBtn v-if="item.tracking_number" class="mr-1"/>-->
<!--                <ValueHelper-->
<!--                    :value="item.recipient_address"-->
<!--                />-->
                <TooltipTextHelper
                    :text="item.recipient_address"
                    :paragraph="item.recipient_address"
                />
              </div>
            </td>
            <td>
              {{item.products[0]["name"]}}
            </td>
            <td>
              {{ item.shipped_at | moment("DD MMM, YYYY") }}
            </td>
            <td width="100%" v-if="$store.getters.getIsAdminRights === 'user'"></td>
            <td>
              <div class="table-right table-manage-list table-manage-list--small"
                   v-if="$store.getters.getGlobalUpdateTable"
              >
                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random())"
                               :key="$store.getters.getGlobalUpdateManagerButton"
                    class="width-scoped"
                >
                  <template slot="item"
                            v-if="(item.order_internal_status_id !== INTERNAL_STATUSES.COMPLETE.id) || isAdmin"
                  >
                    <LinkButton
                        :value="$t('common_edit.localization_value.value')"
                        :type="'edit'"
                        :link="$store.getters.GET_PATHS.belposhta + '/' + item.id"
                    />
                  </template>

                  <template slot="item"
                            v-if="(item.order_internal_status_id !== INTERNAL_STATUSES.COMPLETE.id) ||
                            $store.getters.getIsAdminRights === 'admin'"
                  >
                    <LinkButton
                        :value="$t('common_delete.localization_value.value')"
                        :type="'delete'"
                        @click.native="removeOrderInternal(item.id)"
                    />
                  </template>

                  <template slot="item"
                  >
                    <LinkButton
                        :value="$t('common_copy.localization_value.value')"
                        :type="'copy'"
                        @click.native="copyOrder(item.id)"
                    />
                  </template>

                  <template slot="item"
                            v-if="(item.order_internal_status_id !== INTERNAL_STATUSES.COMPLETE.id)"
                  >
                    <LinkButton
                        :value="$t('common_create.localization_value.value')"
                        :type="'dog'"
                        @click.native="createLabel(item.id, true)"
                        :disabled="$store.getters.getBelPoshtaBtn"
                    />
                  </template>

                  <template slot="item"
                    v-if="(item.order_internal_status_id === INTERNAL_STATUSES.COMPLETE.id)"
                  >
                    <LinkButton
                        :value="$t('poshta_customsDeclarationCN22.localization_value.value')"
                        :type="'cn22'"
                        @click.native="belposhtaProformaCN22(item.id)"
                    />
                  </template>
                </ManagerButton>


              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getOrdersInternal"
                 :key="index"
            >
              <BelposhtaTableMobile
                  :item="item"
                  @openPoshtaLink="openPoshtaLink"
                  @tableCreateLabel="tableCreateLabel(item)"
                  @removeOrderInternal="removeOrderInternal(item.id)"
                  @createLabel="createLabel(item.id, true)"
                  @belposhtaProformaCN22="belposhtaProformaCN22(item.id)"
                  @copyOrder="copyOrder(item.id)"
              />
            </div>
          </div>
        </div>


        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getOrdersInternalCommonList.next_page_url !== null && $store.getters.getOrdersInternal.length > 0"
                @click.native="$emit('showMore')"
                v-bind:class="{'disabled-btn' : $store.getters.getNextOrdersInternalPage}"
                :count="$store.getters.getOrdersInternalCommonList.total - $store.getters.getOrdersInternalForPage * $store.getters.getOrdersInternalCommonList.current_page < $store.getters.getOrdersInternalForPage ?
                  $store.getters.getOrdersInternalCommonList.total - $store.getters.getOrdersInternalForPage * $store.getters.getOrdersInternalCommonList.current_page:
                  $store.getters.getOrdersInternalForPage"
            />
          </div>
        </div>

      </div>
    </template>

    <template v-if="$store.getters.getOrdersInternalLoading === false && $store.getters.getOrdersInternal.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>


    <PayOrderPopup
        v-if="payOrderPopup"
        :orderPrice="orderPrice"
        @close="changePayOrderPopup(false)"
        @createLabel="createLabel(orderId, true)"
    />


  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import {belposhtaOrdersTableMixins} from "../../../../../../../mixins/internalOrdersMixins/belposhtaOrdersMixin";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {INTERNAL_STATUSES} from "../../../../../../../staticData/staticVariables";
  import BelposhtaTableMobile from "./BelposhtaTableMobile/BelposhtaTableMobile";
  import PayOrderPopup from "../../../../../../coreComponents/Popups/PayOrderPopup/PayOrderPopup";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import InternalTrackingNumber
    from "../../../../../../coreComponents/TableComponents/InternalTrackingNumber/InternalTrackingNumber";
  import TooltipTextHelper from "../../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";




  export default {
    name: "BelposhtaTable",

    components: {
      TableUserColumn,
      InternalTrackingNumber,
      ShowMore,
      LinkButton,
      ManagerButton,
      ValueHelper,
      BelposhtaTableMobile,
      PayOrderPopup,
      NoResult,
      TooltipTextHelper,
    },

    mixins: [mixinDetictingMobile, belposhtaOrdersTableMixins],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },

    computed: {
      // tableData(){
      //   return this.$store.getters.getOrdersInternal
      // }
    },

    watch: {
      // tableData(newVal) {
        // console.log(newVal);
      // }
    },

    data() {
      return {
        orderId: '',
        showContent: false,

        INTERNAL_STATUSES: INTERNAL_STATUSES,

        payOrderPopup: false,
        hideEditField: false,

      }
    },

    methods: {


      tableCreateLabel(item) {
        this.orderId = item.id
        this.getDeliveryPriceFromTable(item.id)
      },

      copyOrder(id) {
        return this.$store.dispatch('copyOrdersInternal', id).then(() => {
          this.$emit('reload')
        })
      },


      // removeOrder(id){
      //   let text = {
      //     title: 'poshta_DeleteBelposhtaOrder',
      //     txt: '',
      //     yes: 'common_confirmDelete',
      //     no: 'common_confirmNo'
      //   }
      //
      //   let confirm = () => {
      //
      //     this.$store.dispatch('deleteOrdersInternal', id).then((response) => {
      //       if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
      //         let orders = this.$store.getters.getOrdersInternal
      //         orders.map((item, index) => {
      //           if(item.id === id) {
      //             orders.splice(index, 1)
      //           }
      //         })
      //
      //         this.openNotify('success', 'common_notificationRecordDeleted')
      //       } else {
      //         setTimeout(() => {
      //           this.openNotify('error', 'common_notificationUndefinedError')
      //         }, 200)
      //       }
      //     })
      //
      //     this.$store.commit('setConfirmDeletePopup', false)
      //     return true
      //   }
      //
      //   this.deleteFunc(text, confirm)
      // }

    }

  }

</script>

<style scoped lang="scss">

  .site-table thead th:first-child,
  .site-table tbody td:first-child{
    padding-right: 0;
  }

  .site-table thead th:last-child,
  .site-table tbody td:last-child{
    padding-right: 0;
  }

  .small-table th,
  .small-table td{
    white-space: nowrap;
  }

  .width-scoped{
    width: 240px;
    max-width: 240px;

    @media (max-width: 1740px) {
      width: 200px;
      max-width: 200px;
    }
  }

  /deep/ .tooltip-helper-full{
    overflow: hidden;
    max-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }



</style>
