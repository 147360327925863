import { render, staticRenderFns } from "./BelposhtaTable.vue?vue&type=template&id=a608d282&scoped=true"
import script from "./BelposhtaTable.vue?vue&type=script&lang=js"
export * from "./BelposhtaTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a608d282",
  null
  
)

export default component.exports