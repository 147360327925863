<template>
  <div class="order-create__section">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_PersonalInformation'])"></div>
      {{$t('poshta_PersonalInformation.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_contactName', ])"></div>
        <DefaultInput
                :label="$t('poshta_contactName.localization_value.value')"
                :type="'text'"
                v-bind:class="{'ui-no-valid': personalInfoFrom.validation.personalName}"
                :errorTxt="$t(`${personalInfoFrom.validationTranslate.personalName}.localization_value.value`)"
                :error="personalInfoFrom.validation.personalName"
                v-model="personalInfoFrom.information.personalName"
        />
      </div>

      <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_phone', ])"></div>
        <DefaultInput
                :label="$t('common_phone.localization_value.value')"
                :type="'text'"
                v-bind:class="{'ui-no-valid': personalInfoFrom.validation.personalPhone}"
                :errorTxt="$t(`${personalInfoFrom.validationTranslate.personalPhone}.localization_value.value`)"
                :error="personalInfoFrom.validation.personalPhone"
                v-model="personalInfoFrom.information.personalPhone"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_email', ])"></div>
        <DefaultInput
                :label="$t('common_email.localization_value.value')"
                :type="'text'"
                v-bind:class="{'ui-no-valid': personalInfoFrom.validation.personalEmail}"
                :errorTxt="$t(`${personalInfoFrom.validationTranslate.personalEmail}.localization_value.value`)"
                :error="personalInfoFrom.validation.personalEmail"
                v-model="personalInfoFrom.information.personalEmail"
        />
      </div>
    </div>


    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_SenderAddress', 'poshta_SenderAddressTooltip'])"></div>
      {{ $t('poshta_SenderAddress.localization_value.value') }}
      <v-popover
              class="site-tooltip"
              offset="5"
              placement="top"
              trigger="hover"

      >
        <TooltipBtn
                class="tooltip-target"

        />
        <template slot="popover">
          <p>
            <b>{{ $t('poshta_SenderAddress.localization_value.value') }}</b>
          </p>
          <p>
            {{$t('poshta_SenderAddressTooltip.localization_value.value')}}
          </p>
        </template>
      </v-popover>
    </div>

    <div class="order-create__section-sub">
      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_address', ])"></div>
          <DefaultInput
                  :label="$t('common_address.localization_value.value')"
                  :type="'text'"
                  v-bind:class="{'ui-no-valid': sendersAddressFrom.validation.address}"
                  :errorTxt="$t(`${sendersAddressFrom.validationTranslate.address}.localization_value.value`)"
                  :error="sendersAddressFrom.validation.address"
                  v-model="sendersAddressFrom.delivery.address"
          />
        </div>
        <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_city', ])"></div>
          <DefaultInput
              :label="$t('common_city.localization_value.value')"
              :options="options"
              v-bind:class="{'ui-no-valid': sendersAddressFrom.validation.city}"
              :errorTxt="$t(`${sendersAddressFrom.validationTranslate.city}.localization_value.value`)"
              :error="sendersAddressFrom.validation.city"
              v-model="sendersAddressFrom.delivery.city"
          />
        </div>
        <div class="order-create__col custom-col custom-col--16  custom-col--md-25 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_zipCode', ])"></div>
          <DefaultInput
                  :label="$t('common_zipCode.localization_value.value')"
                  :type="'text'"
                  v-bind:class="{'ui-no-valid': sendersAddressFrom.validation.zip}"
                  :errorTxt="$t(`${sendersAddressFrom.validationTranslate.zip}.localization_value.value`)"
                  :error="sendersAddressFrom.validation.zip"
                  v-model="sendersAddressFrom.delivery.zip"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>

  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  import TooltipBtn from "../../../../../UI/tooltips/TooltipBtn/TooltipBtn";


  export default {
    name: "StepOne",
    components: {
      DefaultInput,
      TooltipBtn,
    },

    mixins: [mixinDetictingMobile],

    props: {
      belposhta: Object,
      personalInfoFrom: Object,
      sendersAddressFrom: Object,

      serverError: {
        type: Boolean,
        default: false,
      },
    },

    data(){
      return {
        options: [{}],
        isModalCustomLimitsPopup: false,
      }
    },

    methods: {

    },
  }
</script>

<style scoped>

</style>
